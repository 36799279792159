exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-blogs-prismic-blog-page-uid-tsx": () => import("./../../../src/pages/blogs/{PrismicBlogPage.uid}.tsx" /* webpackChunkName: "component---src-pages-blogs-prismic-blog-page-uid-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-prismic-teamble-page-url-tsx": () => import("./../../../src/pages/{PrismicTeamblePage.url}.tsx" /* webpackChunkName: "component---src-pages-prismic-teamble-page-url-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-security-features-tsx": () => import("./../../../src/pages/security-features.tsx" /* webpackChunkName: "component---src-pages-security-features-tsx" */),
  "component---src-pages-terms-of-services-tsx": () => import("./../../../src/pages/terms-of-services.tsx" /* webpackChunkName: "component---src-pages-terms-of-services-tsx" */)
}

